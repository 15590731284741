import styled from "styled-components"

export const StyledOkolica = styled.section`
    widht: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 60px 40px;
    max-width: 1400px;
    margin: 0 auto;
    > p{
        width: 50%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 160.7%;
        padding: 5px;
    }

    @media only screen and (max-width: 1122px){
        > p{
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 767px){
        > p{
            width: 100%;
            font-size: 20px;
        }
    }
    @media only screen and (max-width: 767px){
        padding: 40px 20px;
        > p{
            font-size: 16px;
        }
    }
`