/** @format */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Footer from "../components/Footer/Footer";
import GlobalLayout from "../components/layouts/GlobalLayout";
import SiteHeader from "../components/SiteHeader/SiteHeader";
import HelmetTemplate from "../components/HelmetTemplate/HelmetTemplate";

import { StyledOkolica } from "../components/Okolica/StyledOkolica";

const Okolica = () => {
    const data = useStaticQuery(graphql`
        query Okolica {
            datoCmsOkolica {
                okolica {
                    element
                }
            }
        }
    `);

    return (
        <GlobalLayout>
            <HelmetTemplate
                title="Okolica"
                desc="Co znajduje się w naszej okolicy"
            />
            <SiteHeader title="Atrakcje w pobliżu" />
            <StyledOkolica>
                {data.datoCmsOkolica.okolica.map(({ element }) => (
                    <p>{element}</p>
                ))}
            </StyledOkolica>
            <Footer />
        </GlobalLayout>
    );
};

export default Okolica;
